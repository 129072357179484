var features_detected = ('fetch' in window && 'Promise' in window);
if(!features_detected){
    var scriptElement = document.createElement('script');
    scriptElement.async = false;
    scriptElement.src = '/polyfills.js';
    document.head.appendChild(scriptElement);
}

var cache = {};

function set_background() {
    var n = Math.floor(Math.random() * 44) + 1;
    $.backstretch("images/bg/bg" + n + ".jpg");
}

function set_href(element) {
    var e = document;
    if (element) e = element;
    var as = e.querySelectorAll("a");
    for (var i = 0; i < as.length; i++) {
        if(as[i].getAttribute("href").indexOf("akcie/")>-1){
            continue;
        }
        as[i].addEventListener("click", function (e) {
            load_content(e.currentTarget.getAttribute("href"));
            e.preventDefault();
            return false;
        })
    }

}

function load_content(id) {
    $("#content_wrapper").fadeOut(200,function(){

        fetch_content(id).then(res => {
            history.pushState({ "id": id }, "", id)
            render(res);
            $("#content_wrapper").fadeIn(200);
        });

    });
}

function fetch_content(id) {
    return (id in cache)
        ? Promise.resolve(cache[id])
        : fetch("data/" + id).then(res => { return res.text(); }).then(res => {
            var r = res.split(";;;");
            var data = {};
            var content = "";
            if (r.length < 2) {
                content = r[0];
            }
            else {
                try {
                    data = JSON.parse(r[0]);
                }
                catch (e) {
                    console.log("failed to parse data")
                    data = {};
                }
                content = r[1];
            }
            cache[id] = { "data": data, "content": content };
            return cache[id];
        })
}

function build_gallery(dir, images){
    var el = document.createElement("div");
    el.setAttribute("id","gallery");
    el.setAttribute("class","flex flex-wrap mt3-ns mt1 justify-center");
    if(dir[dir.length-1]!='/'){
        dir = dir+'/';
    }
    images.forEach(function(v){
        var img = document.createElement("img");
        img.setAttribute("src",dir+"thumbs/t_"+v);
        var a = document.createElement("a");
        a.setAttribute("class","ma1-ns");
        a.setAttribute("href",dir+v);
        a.appendChild(img);
        el.appendChild(a);
    });
    return el;
}

function render(v) {
    var ec = document.getElementById("content");
    var ect = document.getElementById("title");
    ect.innerHTML = "";
    if ("content" in v) {
        ec.innerHTML = v["content"];
    }
    if ("data" in v && "title" in v.data) {
        ect.innerHTML = v.data["title"];
    }

    set_href(ec); //modify anchors to fetch data only

    if ("data" in v && "gallery" in v.data) {
        var links = v["data"]["images"] || [];
        //(images && Array.isArray(images))?images:v.data["images"];
        if(links && Array.isArray(links) && links.length>0){
            dir = v.data["gallery"];
            gal = build_gallery(dir,links);
            ec.appendChild(gal);
            $("#gallery").lightGallery();
        }
    }
}

function onpopstate() {
    var page = "index";
    if (history.state && "id" in history.state) {
        page = history.state["id"];
    }
    console.log("onpop loading")
    console.log(page);
    fetch_content(page).then(res => {
        render(res);
    }).catch(()=>{
        console("could not fetch")
        history.replaceState({"id":page},"",page);
    });
}

function loaded() {
    set_background();
    set_href();
    //load_content("team");
    if(window.location.pathname.length>1){
        page = window.location.pathname.slice(1);
        history.replaceState({"id":page},"",page);
    };

    // prefetch most probable content
    ["index","about","akcie"].forEach(function(v){
        fetch_content(v);
    });
}

$(document).ready(function() {
    $("#gallery").lightGallery(); 
});
window.addEventListener('DOMContentLoaded', loaded);
window.addEventListener('popstate', onpopstate);